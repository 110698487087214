// IMPORTS
@import (reference) "../settings.less";

//GREETING SECTION
.greeting-section {
    display: flex;
    justify-content: center;
    width: 100%;

    &:has(> *:nth-child(2)) {
        aspect-ratio: ~"1440/478";
    }

    &:has(.greeting-image-wrapper) {
        margin-bottom: 24px;
    }
}

.greeting-image-wrapper {
    width: 46.875%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    background: white;
    position: relative;
    justify-content: flex-end;

    figure {
        width: 100%;
        aspect-ratio: ~"675/454";
        position: relative;

        img {
            .img-crop();
            border-radius: 0 0 8px 8px;
        }
    }

    .decal {
        bottom: -43px;
        right: -43px;
    }

    &:only-child {

        img {
            border-radius: 8px;
        }
    }
}

.greeting {
    width: 53.125%;
    padding: var(--rowGap) 0 var(--rowGap) 48px;
    position: relative;
    align-self: flex-start;
    height: 85%;

    &:before {
        .full-bg();
        width: 200vw;
        background-color: @light-bg;
    }

    .greeting-body {
        letter-spacing: 0.02em;
        overflow-y: auto;
        max-height: 96%;
        padding-right: 8px;
    }

    &:only-child {
        width: 100%;
        height: auto;
        text-align: center;
        padding-inline: 16%;

        .greeting-body {
            max-height: none;
            padding-right: 0;
        }
    }
}

// BLOG BANNERS
.blog-banners-wrapper {
    .grid();
    grid-template-columns: repeat(3, 1fr);
    gap: 56px 30px;
    position: relative;
}

.blog-banner {
    .grid();
    grid-template-rows: auto auto 1fr;
    width: 100%;

    .img-wrapper {
        margin-bottom: 20px;
        width: 100%;
        aspect-ratio: ~"460/230";

        img {
            .img-crop();
            border-radius: 8px;
        }
    }

    b {
        font-size: 18px;
        color: @header-color;
        text-transform: uppercase;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(2 * 18px * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline;
        text-decoration-color: transparent;
    }

    span {
        margin-top: 8px;
        letter-spacing: 0.02em;
        color: @font-color;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(5 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .red-button {
        align-self: end;
    }

    &:hover {

        b {
            text-decoration-color: initial;
        }
    }
}
